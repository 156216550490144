import React, {useEffect, useState} from 'react';
import {get, patch} from '../../clients/mobile-backend.client';
import {SoundNotificationManager} from '../activeorders/soundNotificationManager';
import AriEventsModal from './ariEventsModal';

interface AriEventDto {
  siteId: string;
  orderId: string;
  ariConfirmed: 'PENDING' | 'ACCEPTED' | 'DENIED';
}

interface AriEventsNotificationProps {
  siteId: string;
  soundManager: SoundNotificationManager;
  isReady: boolean;
}

const AriEventsNotification: React.FC<AriEventsNotificationProps> = ({ siteId, soundManager, isReady }) => {
  const [pendingAriEvents, setPendingAriEvents] = useState<AriEventDto[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedAriEvent, setSelectedAriEvent] = useState<AriEventDto | undefined>(undefined);
  const [fetchAriEventsErrorMessage, setFetchAriEventsErrorMessage] = useState<string | null>(null);
  const [patchAriEventErrorMessage, setPatchAriEventErrorMessage] = useState<string | null>(null);


  useEffect(() => {
    const pollAriEvents = () => {
      get<AriEventDto[]>(`/api/v2/store/${siteId}/agreements/ari-events?ariConfirmed=PENDING`)
      .then(response => {
        setFetchAriEventsErrorMessage(null);
        if (response.data.length > 0) {
          setPendingAriEvents(response.data);
          setSelectedAriEvent(response.data[0]);
          setShowModal(true);
          handleSoundTrigger('trigger');
        }
      })
      .catch(error => {
        if (error.response?.status === 404) {
          // Treat 404 as no ARI events found
          setPendingAriEvents([]);
          setShowModal(false);
          handleSoundTrigger('stop');
          setFetchAriEventsErrorMessage(null);
        } else {
          console.error('Error fetching ARI events:', error);
          setFetchAriEventsErrorMessage('Error fetching ARI events.');
          handleSoundTrigger('stop');
        }
      });
    };

    const intervalId = setInterval(pollAriEvents, 10000); // Poll every 10 seconds
    return () => clearInterval(intervalId);
  }, [siteId, soundManager, isReady]);

  const handleAriEventAction = (decision: 'ACCEPTED' | 'DENIED') => {
    if (!selectedAriEvent) return;

    patch(`/api/v2/store/${selectedAriEvent.siteId}/agreements/ari-events/${selectedAriEvent.orderId}`, {
      ariConfirmed: decision
    })
    .then(() => {
      const remainingEvents = pendingAriEvents.filter(event => event.orderId !== selectedAriEvent.orderId);
      setPendingAriEvents(remainingEvents);
      setSelectedAriEvent(remainingEvents.length > 0 ? remainingEvents[0] : undefined);
      setShowModal(remainingEvents.length > 0);

      setPatchAriEventErrorMessage(null);

      if (remainingEvents.length === 0) {
        handleSoundTrigger('stop');
      }
    })
    .catch(error => {
      console.error('Error updating ARI event:', error);
      setPatchAriEventErrorMessage('Failed to update the ARI event. Please try again.');
    });
  };

  const handleSoundTrigger = (action: 'trigger' | 'stop') => {
    if (isReady) {
      if (action === 'trigger') {
        console.log('Triggering pending ARI event sound...');
        soundManager.triggerAriEventSound();
      } else if (action === 'stop') {
        console.log('Stopping pending ARI event sound...');
        soundManager.stopAriEventSound();
      }
    } else {
      console.log(`Sound system not ready, skipping ${action} action.`);
    }
  };

  const renderFetchAriEventsErrorMessage = () => {
    if (fetchAriEventsErrorMessage) {
      return (
          <p
              style={{
                color: 'red',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '16px',
              }}
          >
            {fetchAriEventsErrorMessage}
          </p>
      );
    }
    return null;
  };

  return (
      <>
        {renderFetchAriEventsErrorMessage()}
        <AriEventsModal
          showModal={showModal}
          selectedAriEvent={selectedAriEvent}
          onAriEventAction={handleAriEventAction}
          patchAriEventErrorMessage={patchAriEventErrorMessage}
        />
      </>
  );
};

export default AriEventsNotification;
